// utils.js
import { Timestamp } from 'firebase/firestore';

export const formatDate = (dateInput) => {
  if (!dateInput) return 'N/A'; // Return 'N/A' if the input is null, undefined, or falsy

  let date;
  if (dateInput instanceof Timestamp) {
    // If it's a Firestore Timestamp, convert to a JS Date
    date = dateInput.toDate();
  } else if (dateInput instanceof Date) {
    // If it's already a JS Date, use it as is
    date = dateInput;
  } else if (typeof dateInput === 'string') {
    // If it's a string, try to parse it into a JS Date
    date = new Date(dateInput);
    if (isNaN(date)) return 'Invalid Date'; // Handle invalid date strings
  } else {
    return 'Invalid Date'; // Handle unsupported input types
  }

  // Format the date into DD-MM-YYYY
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};
