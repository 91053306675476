import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Import your custom auth hook
import { Alert, Container } from '@mui/material'; // Import for showing alert message

const PrivateRoute = ({ allowedRoles }) => {
  const { currentUser, userRole, userStatus } = useAuth(); // Get the current user, role, and status

  // Debugging: Log current user, role, and status
  console.log('PrivateRoute - Current User:', currentUser);
  console.log('PrivateRoute - User Role:', userRole);
  console.log('PrivateRoute - User Status:', userStatus);
  console.log('PrivateRoute - Allowed Roles:', allowedRoles);

  // If there is no authenticated user, redirect to login
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // If the user's status is 'pending', block access and show a message
  if (userStatus === 'pending') {
    return (
      <Container>
        <Alert severity="warning">
          Your account is awaiting approval. Once you've verified your email address, your account will be aprpoved ASAP.
        </Alert>
      </Container>
    );
  }

  // If the user's status is 'denied', block access and show a message
  if (userStatus === 'denied') {
    return (
      <Container>
        <Alert severity="error">
          Your account has been denied. Please contact support if you believe this is a mistake.
        </Alert>
      </Container>
    );
  }

  // If allowedRoles is undefined, just check for authentication (used for profile route)
  if (!allowedRoles) {
    return <Outlet />;
  }

  // If the user's role is included in allowedRoles, allow access
  if (allowedRoles.includes(userRole)) {
    return <Outlet />;
  }

  // Otherwise, redirect the user to a "not authorized" page or home
  return <Navigate to="/" />;
};

export default PrivateRoute;
