import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LicenseeServices.css'; // Optional: CSS file for styling

function LicenseeServices() {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div className="licensee-services-container">
            <h1>User Services</h1>
            <div className="services-grid">
                <div className="service-box" onClick={() => handleNavigation('/report-issue')}>
                    <h2>Report Maintenance Issue</h2>
                    <p>Click here to report any maintenance issues.</p>
                </div>
                <div className="service-box" onClick={() => handleNavigation('/pay-rent')}>
                    <h2>Pay Rent</h2>
                    <p>Click here to pay your rent online.</p>
                </div>
                <div className="service-box" onClick={() => handleNavigation('/get-documents')}>
                    <h2>Get Proof of Address</h2>
                    <p>Click here to access your documents.</p>
                </div>
                <div className="service-box" onClick={() => handleNavigation('/give-notice')}>
                    <h2>Give Notice</h2>
                    <p>Click here to give notice for moving out.</p>
                </div>
            </div>

            {/* Add House Rules link below the services grid */}
            <div className="house-rules-link" onClick={() => handleNavigation('/house-rules')}>
                <h2>House Rules</h2>
                <p>Click here to read the house rules.</p>
            </div>
        </div>
    );
}

export default LicenseeServices;