import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  Paper,
  FormControlLabel,
  Checkbox,
  Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { sendEmailVerification } from 'firebase/auth'; // Import sendEmailVerification

function Register() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { signup } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [gdprConsent, setGdprConsent] = useState(false);
  const [loading, setLoading] = useState(false);

  // Password validation logic
  const validatePassword = (password) => {
    if (password.length < 6) {
      return 'Password must be at least 6 characters long';
    }
    if (!/[A-Z]/.test(password)) {
      return 'Password must contain at least one uppercase letter';
    }
    if (!/[0-9]/.test(password)) {
      return 'Password must contain at least one number';
    }
    return '';
  };

  // Handle registration form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!gdprConsent) {
      setError('You must consent to our GDPR policy before registering.');
      return;
    }

    // Validate password
    const passwordValidationMessage = validatePassword(password);
    if (passwordValidationMessage) {
      setPasswordError(passwordValidationMessage);
      return;
    }

    setError('');
    setPasswordError('');
    setLoading(true);

    try {
      const user = await signup(email, password);

      // Send email verification
      await sendEmailVerification(user);

      const docData = {
        fullName: `${firstName} ${lastName}`,
        email,
        role: 'user',
        status: 'pending',
        emailVerified: false, // New field to track email verification
      };

      await setDoc(doc(db, 'users', user.uid), docData);

      setLoading(false);
      navigate('/registration-success');
    } catch (err) {
      console.error('Error during registration:', err);
      setError('Failed to create an account. Please try again.');
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Paper elevation={3} sx={{ p: 3 }}>
        <Box sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Register
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          {passwordError && <Alert severity="error">{passwordError}</Alert>}

          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="First Name"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            <TextField
              fullWidth
              label="Last Name"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
            <TextField
              fullWidth
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              error={!!passwordError}
              helperText={passwordError}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={gdprConsent}
                  onChange={(e) => setGdprConsent(e.target.checked)}
                  name="gdprConsent"
                  required
                />
              }
              label={
                <>
                  I consent to the storage of my personal information in accordance with the{' '}
                  <Link href="/privacy-policy" target="_blank" rel="noopener">
                    GDPR Policy
                  </Link>
                  .
                </>
              }
            />

            <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
              {loading ? 'Registering...' : 'Sign Up'}
            </Button>
          </form>
        </Box>
      </Paper>
    </Container>
  );
}

export default Register;
