import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Paper,
  Box,
  Button,
  Modal,
  Tabs,
  Tab,
} from '@mui/material';
import {
  doc,
  getDocs,
  updateDoc,
  collection,
  query,
  where,
  orderBy,
} from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import useMediaQuery from '@mui/material/useMediaQuery';

ChartJS.register(ArcElement, Tooltip, Legend);

function Home() {
  const { currentUser, userRole, userStatus, loading } = useAuth();
  const [pendingUsers, setPendingUsers] = useState([]);
  const [approvedUsers, setApprovedUsers] = useState([]);
  const [totalTenants, setTotalTenants] = useState(0);
  const [movingOutCount, setMovingOutCount] = useState(0);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [openModal, setOpenModal] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  // Wait for loading to complete before rendering
  if (loading) {
    return null; // Or you can return a loading spinner here
  }

  // Redirect unauthenticated users to login page
  useEffect(() => {
    if (!currentUser && !loading) {
      navigate('/login');
    }
  }, [currentUser, navigate, loading]);

  // Redirect users with 'user' role to '/licensee-services'
  useEffect(() => {
    if (userRole === 'user' && !loading) {
      navigate('/licensee-services');
    }
  }, [userRole, navigate, loading]);

  // Fetch tenants data only if user is 'admin' or 'viewings' and not loading
  useEffect(() => {
    if ((userRole === 'admin' || userRole === 'viewings') && !loading) {
      const fetchTenantsData = async () => {
        try {
          const licenseesSnapshot = await getDocs(collection(db, 'licensees'));
          setTotalTenants(licenseesSnapshot.docs.length);

          const noticesSnapshot = await getDocs(collection(db, 'notices'));
          setMovingOutCount(noticesSnapshot.docs.length);
        } catch (error) {
          console.error('Error fetching tenants data:', error);
        }
      };
      fetchTenantsData();
    }
  }, [userRole, loading]);

  // Fetch users data only if user is 'admin' or 'viewings' and not loading
  useEffect(() => {
    if ((userRole === 'admin' || userRole === 'viewings') && !loading) {
      const fetchUsers = async () => {
        const usersRef = collection(db, 'users');

        // Fetch pending users
        const pendingQuery = query(usersRef, where('status', '==', 'pending'));
        const pendingSnapshot = await getDocs(pendingQuery);
        const pendingUsersList = pendingSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch approved users
        const approvedQuery = query(
          usersRef,
          where('status', '==', 'approved'),
          orderBy('emailVerified', 'desc')
        );
        const approvedSnapshot = await getDocs(approvedQuery);
        const approvedUsersList = approvedSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch all users
        const allUsersSnapshot = await getDocs(usersRef);
        const allUsersList = allUsersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setPendingUsers(pendingUsersList);
        setApprovedUsers(approvedUsersList);
        setAllUsers(allUsersList);
      };

      fetchUsers();
    }
  }, [userRole, loading]);

  const approveUser = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { status: 'approved' });
      setPendingUsers(pendingUsers.filter((user) => user.id !== userId));
    } catch (error) {
      console.error('Error approving user:', error);
    }
  };

  const denyUser = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { status: 'denied' });
      setPendingUsers(pendingUsers.filter((user) => user.id !== userId));
    } catch (error) {
      console.error('Error denying user:', error);
    }
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const bedData = {
    labels: ['Occupied Beds', 'Upcoming Empty Beds'],
    datasets: [
      {
        data: [totalTenants - movingOutCount, movingOutCount],
        backgroundColor: ['#36A2EB', '#FF6384'],
        hoverBackgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // If user status is pending, show pending approval message
  if (userStatus === 'pending') {
    return (
      <Container>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4">Your Account is Pending Approval</Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Please wait for an administrator to approve your account.
          </Typography>
        </Paper>
      </Container>
    );
  }

  // If user role is not 'admin' or 'viewings', render nothing
  if (userRole !== 'admin' && userRole !== 'viewings') {
    return null;
  }

  // Render admin content for 'admin' or 'viewings' roles
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Box sx={{ width: { xs: '90%', md: '50%' } }}>
            <Pie data={bedData} />
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <Paper
            elevation={3}
            sx={{ p: { xs: 2, md: 3 }, textAlign: 'center' }}
          >
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              centered
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Recently Approved" />
              <Tab label="Pending" />
              <Tab label="All Registered" />
            </Tabs>

            {tabIndex === 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h5">
                  Recently Approved Licensees
                </Typography>
                <Grid container spacing={2}>
                  {approvedUsers.map((user) => (
                    <Grid item xs={12} md={6} key={user.id}>
                      <Paper
                        elevation={2}
                        sx={{ p: 2, backgroundColor: '#d4edda' }}
                      >
                        <Typography variant="h6">{user.fullName}</Typography>
                        <Typography>Email: {user.email}</Typography>
                        <Typography>
                          Email Verified: {user.emailVerified ? 'Yes' : 'No'}
                        </Typography>
                        <Typography>
                          Reference Code: {user.referenceCode}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            {tabIndex === 1 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h5">
                  Pending Users for Manual Approval
                </Typography>
                <Grid container spacing={2}>
                  {pendingUsers.map((user) => (
                    <Grid item xs={12} md={6} key={user.id}>
                      <Paper
                        elevation={2}
                        sx={{ p: 2, backgroundColor: '#fff3cd' }}
                      >
                        <Typography variant="h6">{user.fullName}</Typography>
                        <Typography>Email: {user.email}</Typography>
                        <Typography>
                          Email Verified: {user.emailVerified ? 'Yes' : 'No'}
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ fontSize: '0.875rem', mr: 2 }}
                            onClick={() => approveUser(user.id)}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            sx={{ fontSize: '0.875rem' }}
                            onClick={() => denyUser(user.id)}
                          >
                            Deny
                          </Button>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            {tabIndex === 2 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h5">All Registered Users</Typography>
                <Grid container spacing={2}>
                  {allUsers.map((user) => (
                    <Grid item xs={12} md={6} key={user.id}>
                      <Paper elevation={2} sx={{ p: 2 }}>
                        <Typography variant="h6">{user.fullName}</Typography>
                        <Typography>Email: {user.email}</Typography>
                        <Typography>
                          Email Verified: {user.emailVerified ? 'Yes' : 'No'}
                        </Typography>
                        <Typography>Status: {user.status}</Typography>
                        {user.referenceCode && (
                          <Typography>
                            Reference Code: {user.referenceCode}
                          </Typography>
                        )}
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Modal for viewing pending user list on mobile */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="pending-users-modal-title"
        aria-describedby="pending-users-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          <Typography
            id="pending-users-modal-title"
            variant="h6"
            component="h2"
            textAlign="center"
          >
            Pending Users for Approval
          </Typography>
          <Grid container spacing={2}>
            {pendingUsers.map((user) => (
              <Grid item xs={12} key={user.id}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 2,
                    backgroundColor: '#fff3cd',
                  }}
                >
                  <Typography variant="h6">{user.fullName}</Typography>
                  <Typography>Email: {user.email}</Typography>
                  <Typography>
                    Email Verified: {user.emailVerified ? 'Yes' : 'No'}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ fontSize: '0.875rem', mr: 2 }}
                      onClick={() => approveUser(user.id)}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ fontSize: '0.875rem' }}
                      onClick={() => denyUser(user.id)}
                    >
                      Deny
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
}

export default Home;
