import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Paper,
  Box,
  Button,
  Tabs,
  Tab,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext'; // Import the authentication context
import { db } from '../firebase';

const UserManager = () => {
  const { userRole, loading } = useAuth(); // Fetch user role and loading state from context
  const [users, setUsers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [unverifiedUsers, setUnverifiedUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [updatedUser, setUpdatedUser] = useState({});

  const fetchUsers = async () => {
    const usersRef = collection(db, 'users');

    // Fetch all users
    const allUsersSnapshot = await getDocs(usersRef);
    const allUsersList = allUsersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Fetch pending users
    const pendingQuery = query(usersRef, where('status', '==', 'pending'));
    const pendingSnapshot = await getDocs(pendingQuery);
    const pendingUsersList = pendingSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Fetch unverified users
    const unverifiedQuery = query(usersRef, where('emailVerified', '==', false));
    const unverifiedSnapshot = await getDocs(unverifiedQuery);
    const unverifiedUsersList = unverifiedSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setUsers(allUsersList);
    setPendingUsers(pendingUsersList);
    setUnverifiedUsers(unverifiedUsersList);
    setAllUsers(allUsersList);
  };

  useEffect(() => {
    if (userRole === 'admin') {
      fetchUsers();
    }
  }, [userRole]);

  const openEditDialog = (user) => {
    setSelectedUser(user);
    setUpdatedUser(user);
    setEditDialogOpen(true);
  };

  const handleEditChange = (field, value) => {
    setUpdatedUser((prev) => ({ ...prev, [field]: value }));
  };

  const saveUpdates = async () => {
    if (selectedUser && updatedUser) {
      const userRef = doc(db, 'users', selectedUser.id);
      await updateDoc(userRef, updatedUser);
      setEditDialogOpen(false);
      fetchUsers();
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const renderUserCard = (user) => (
    <Grid item xs={12} md={6} key={user.id}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6">{user.fullName}</Typography>
        <Typography>Email: {user.email}</Typography>
        <Typography>
          Status: {user.status} | Email Verified: {user.emailVerified ? 'Yes' : 'No'}
        </Typography>
        {user.referenceCode && <Typography>Reference Code: {user.referenceCode}</Typography>}
        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            onClick={() => openEditDialog(user)}
            sx={{ mr: 2 }}
          >
            Edit
          </Button>
        </Box>
      </Paper>
    </Grid>
  );

  // Render loading spinner if AuthContext is still loading
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  // Render "Access Denied" message for non-admin users
  if (userRole !== 'admin') {
    return (
      <Container>
        <Typography variant="h4" sx={{ mt: 4, textAlign: 'center' }}>
          You don't have access to this page.
        </Typography>
      </Container>
    );
  }

  // Render the User Manager for admin users
  return (
    <Container>
      <Typography variant="h4" sx={{ mb: 4 }}>
        User Management
      </Typography>

      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        sx={{ mb: 3 }}
      >
        <Tab label="Pending Users" />
        <Tab label="Unverified Users" />
        <Tab label="All Users" />
      </Tabs>

      {tabIndex === 0 && (
        <Grid container spacing={2}>
          {pendingUsers.map(renderUserCard)}
        </Grid>
      )}

      {tabIndex === 1 && (
        <Grid container spacing={2}>
          {unverifiedUsers.map(renderUserCard)}
        </Grid>
      )}

      {tabIndex === 2 && (
        <Grid container spacing={2}>
          {allUsers.map(renderUserCard)}
        </Grid>
      )}

      {/* Edit Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Full Name"
            value={updatedUser.fullName || ''}
            onChange={(e) => handleEditChange('fullName', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Email"
            value={updatedUser.email || ''}
            onChange={(e) => handleEditChange('email', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Status"
            value={updatedUser.status || ''}
            onChange={(e) => handleEditChange('status', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Reference Code"
            value={updatedUser.referenceCode || ''}
            onChange={(e) => handleEditChange('referenceCode', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Email Verified"
            value={updatedUser.emailVerified ? 'true' : 'false'}
            onChange={(e) => handleEditChange('emailVerified', e.target.value === 'true')}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={saveUpdates} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UserManager;
