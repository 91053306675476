import React, { useState, useEffect } from 'react';
import { db, storage } from '../firebase';
import { collection, getDocs, query, where, doc, deleteDoc, setDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Alert,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { generateContract } from '../utils/docxUtils';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

// Helper function to get Firebase Auth token
async function getAuthToken() {
  const auth = getAuth();
  return await auth.currentUser.getIdToken(); // Get the ID token for the current user
}

function LicenseeForm({ preSelectedReferenceCode, preSelectedMoveInDate }) {
  const [rentDate, setRentDate] = useState('');
  const [bedroom, setBedroom] = useState('');
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [referenceCode, setReferenceCode] = useState(preSelectedReferenceCode || '');
  const [deposit, setDeposit] = useState('');
  const [rent, setRent] = useState('');
  const [movedIn, setMovedIn] = useState(preSelectedMoveInDate || ''); // Initialize with preSelectedMoveInDate
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [eircode, setEircode] = useState('');
  const [address, setAddress] = useState('');
  const { currentUser, userRole } = useAuth();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [propertys, setPropertys] = useState([]); // List of unique property codes
  const [selectedProperty, setSelectedProperty] = useState(null); // Selected property code
  const [referenceCodes, setReferenceCodes] = useState([]); // Available reference codes for the property
  const [rentLocked, setRentLocked] = useState(true); // Whether rent is locked
  const [depositLocked, setDepositLocked] = useState(true); // Whether deposit is locked
  const [bedroomLocked, setBedroomLocked] = useState(true); // Whether bedroom is locked

  // New states for existing licensee handling
  const [dialogOpen, setDialogOpen] = useState(false);
  const [existingLicensee, setExistingLicensee] = useState(null);
  const [existingLicenseeId, setExistingLicenseeId] = useState(null);

  // Fetch licensees to populate properties and reference codes
  useEffect(() => {
    const fetchLicensees = async () => {
      console.log('Fetching licensees...');
      try {
        const licenseeCollection = collection(db, 'licensees');
        const licenseeSnapshot = await getDocs(licenseeCollection);
        const licensees = licenseeSnapshot.docs.map((doc) => doc.data());

        const propertyCodes = Array.from(
          new Set(
            licensees
              .map((licensee) => {
                if (licensee['REFERENCE CODE'] && typeof licensee['REFERENCE CODE'] === 'string') {
                  return licensee['REFERENCE CODE'].replace(/\d+$/, ''); // Remove trailing digits
                }
                return null; // Exclude invalid entries
              })
              .filter(Boolean) // Remove null values
          )
        );

        setPropertys(propertyCodes);

        if (preSelectedReferenceCode) {
          // Infer the property from the pre-selected reference code
          const inferredProperty = preSelectedReferenceCode.replace(/\d+$/, ''); // Extract the property part
          setSelectedProperty(inferredProperty); // Set the property
          handlePropertySelect(inferredProperty); // Trigger the population of reference codes
        }
      } catch (err) {
        console.error('Failed to fetch licensees:', err);
        setError('Failed to fetch licensees: ' + err.message);
      }
    };

    fetchLicensees();
  }, [preSelectedReferenceCode]);

  // Pre-populate form fields if preSelectedReferenceCode is provided
  useEffect(() => {
    if (preSelectedReferenceCode) {
      handleReferenceCodeSelect(preSelectedReferenceCode);
    }
  }, [preSelectedReferenceCode]);

  // Handle Property Selection
  const handlePropertySelect = async (property) => {
    console.log('Property selected:', property);
    setSelectedProperty(property);

    try {
      const licenseeCollection = collection(db, 'licensees');
      const licenseeSnapshot = await getDocs(licenseeCollection);
      const licensees = licenseeSnapshot.docs.map((doc) => doc.data());

      const propertyReferenceCodes = licensees
        .map((licensee) => licensee['REFERENCE CODE'])
        .filter((code) => typeof code === 'string' && code.startsWith(property))
        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));

      setReferenceCodes(propertyReferenceCodes);

      const matchingLicensee = licensees.find((licensee) => licensee['REFERENCE CODE'].startsWith(property));
      if (matchingLicensee) {
        setRentDate(matchingLicensee['RENT DATE']);
        setAddress(matchingLicensee['ADDRESS']);
        setEircode(matchingLicensee['EIRCODE']);
        console.log('Matching licensee data:', matchingLicensee);
      }
      setBedroom('');
    } catch (err) {
      console.error('Error handling property select:', err);
      setError('Error handling property select: ' + err.message);
    }
  };

  const handleReferenceCodeSelect = async (reference) => {
    console.log('Reference code selected:', reference);
    setReferenceCode(reference);
  
    try {
      const licenseeCollection = collection(db, 'licensees');
      const licenseeSnapshot = await getDocs(licenseeCollection);
      const licensees = licenseeSnapshot.docs.map((doc) => doc.data());
  
      const selectedLicensee = licensees.find((licensee) => licensee['REFERENCE CODE'] === reference);
      if (selectedLicensee) {
        setRent(selectedLicensee['RENT']);
        setDeposit(selectedLicensee['DEPOSIT']);
        setBedroom(selectedLicensee['BEDROOM']); // Original logic for setting bedroom
        setAddress(selectedLicensee['ADDRESS']);
        setEircode(selectedLicensee['EIRCODE']);
        setRentLocked(true);
        setDepositLocked(true);
        setBedroomLocked(true);
        console.log('Selected licensee data:', selectedLicensee);
      }
    } catch (err) {
      console.error('Error handling reference code select:', err);
      setError('Error handling reference code select: ' + err.message);
    }
  };
  

  // Toggle Rent Change
  const handleRentChange = () => {
    setRentLocked(!rentLocked);
  };

  // Toggle Bedroom Change
  const handleBedroomChange = () => {
    setBedroomLocked(!bedroomLocked);
  };

  // Submit form to Firestore (modified to handle existing licensee & use setDoc)
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submission initiated...');

    if (!currentUser) {
      setError('You must be logged in to add a licensee');
      console.error('Submission error: User not logged in');
      return;
    }

    // Check if user is an admin
    if (userRole !== 'admin') {
      setError('You do not have permission to add a licensee');
      console.error('Submission error: User lacks admin privileges');
      return;
    }

    const licenseeData = {
      'RENT DATE': rentDate,
      BEDROOM: bedroom,
      TYPE: type,
      NAME: name,
      SURNAME: surname,
      'REFERENCE CODE': referenceCode,
      DEPOSIT: deposit,
      RENT: rent,
      'MOVED IN': movedIn,
      EMAIL: email,
      'PHONE NUMBER': phoneNumber,
      EIRCODE: eircode,
      ADDRESS: address,
    };

    console.log('Licensee data to be added or updated:', licenseeData);

    try {
      setError('');
      setMessage('');

      // Check if a licensee with the same reference code already exists
      const q = query(collection(db, 'licensees'), where('REFERENCE CODE', '==', referenceCode));
      const existingDocs = await getDocs(q);

      if (!existingDocs.empty) {
        // Existing document found - ask for confirmation to replace
        const oldLicenseeDoc = existingDocs.docs[0];
        const oldLicenseeData = oldLicenseeDoc.data();
        setExistingLicensee(oldLicenseeData);
        setExistingLicenseeId(oldLicenseeDoc.id);
        window.newLicenseeData = licenseeData;
        setDialogOpen(true);
      } else {
        // No existing licensee found, just add/update the one using the reference code as the doc ID
        await setDoc(doc(db, 'licensees', referenceCode), licenseeData);
        setMessage('Licensee added successfully');
        console.log('Licensee added successfully');
      }
    } catch (err) {
      console.error('Error adding/updating licensee:', err);
      setError('Failed to add/update licensee: ' + err.message);
    }
  };

  // Confirm replacement of existing licensee
  const handleConfirm = async () => {
    if (existingLicenseeId && window.newLicenseeData) {
      try {
        // Delete old licensee
        await deleteDoc(doc(db, 'licensees', existingLicenseeId));
        // Add new licensee with the Reference Code as its ID
        await setDoc(doc(db, 'licensees', window.newLicenseeData['REFERENCE CODE']), window.newLicenseeData);
        setMessage('Old licensee replaced successfully');
        console.log('Old licensee replaced successfully');
      } catch (err) {
        console.error('Error replacing licensee:', err);
        setError('Failed to replace licensee: ' + err.message);
      }
    }
    setDialogOpen(false);
    setExistingLicensee(null);
    setExistingLicenseeId(null);
    delete window.newLicenseeData;
  };

  // Cancel replacement
  const handleCancel = () => {
    setDialogOpen(false);
    setExistingLicensee(null);
    setExistingLicenseeId(null);
    delete window.newLicenseeData;
  };

  // Generate Contract and Upload DOCX to Firebase Storage
  const handleGenerateContract = async () => {
    setMessage('Generating contract...');
    setError('');
    setLoading(true);
    console.log('Contract generation initiated...');

    try {
      const formData = {
        licenseeName: name + ' ' + surname,
        date: movedIn,
        propertyAddress: address,
        room: bedroom,
        referenceCode: referenceCode,
        bankDetails: 'IE86AIBK93101256223078', // Replace with actual bank details
        rentAmount: rent,
        depositAmount: deposit,
        startDate: movedIn,
        endDate: movingOut,
        term: type,
        emailAddress: email,
      };

      console.log('Form data for contract:', formData);

      const docxBlob = await generateContract(formData);
      const storageRef = ref(storage, `contracts/${Date.now()}-contract.docx`);
      const uploadTask = uploadBytesResumable(storageRef, docxBlob, {
        contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          console.error('Error uploading DOCX:', error);
          setError('Failed to upload DOCX: ' + error.message);
          setMessage('');
          setLoading(false);
        },
        async () => {
          try {
            const docxUrl = await getDownloadURL(uploadTask.snapshot.ref);
            const token = await getAuthToken(); // Get Firebase Auth token

            const functionUrl = 'https://europe-west1-lobi-portal.cloudfunctions.net/convertDocxToPdf';
            let conversionResponse;
            let attempts = 0;
            const maxAttempts = 5;

            while (attempts < maxAttempts) {
              try {
                conversionResponse = await axios.post(
                  functionUrl,
                  { docxUrl },
                  {
                    headers: { Authorization: `Bearer ${token}` }, // Include the token in the request
                  }
                );
                break;
              } catch (error) {
                if (error.response && error.response.status === 429) {
                  attempts += 1;
                  await new Promise((resolve) => setTimeout(resolve, 1000 * attempts));
                } else {
                  throw error;
                }
              }
            }

            if (!conversionResponse) {
              throw new Error('Failed to convert DOCX to PDF after multiple attempts');
            }

            const pdfUrl = conversionResponse.data.pdfUrl;
            setMessage('Contract generated successfully! Click the link to download.');
            console.log('PDF generated successfully:', pdfUrl);

            // Download the PDF
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.setAttribute('download', 'contract.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false);
          } catch (conversionError) {
            console.error('Error during PDF conversion:', conversionError);
            setError('Failed to convert DOCX to PDF: ' + conversionError.message);
            setMessage('');
            setLoading(false);
          }
        }
      );
    } catch (err) {
      console.error('Failed to generate contract:', err);
      setError('Failed to generate contract: ' + err.message);
      setMessage('');
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 3 }}>
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Add Licensee
          </Typography>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {message && (
            <Alert severity={message.includes('Generating') ? 'info' : 'success'} sx={{ mb: 2 }}>
              {message}
            </Alert>
          )}
          <form onSubmit={handleSubmit}>
            {/* Property Dropdown */}
            <Autocomplete
              options={propertys}
              getOptionLabel={(option) => option}
              value={selectedProperty}
              onChange={(event, newValue) => handlePropertySelect(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Property" variant="outlined" margin="normal" fullWidth required />
              )}
            />
            <TextField
              fullWidth
              label="Eircode"
              value={eircode}
              onChange={(e) => setEircode(e.target.value)}
              required
              margin="normal"
              disabled
            />
            <TextField
              fullWidth
              label="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
              margin="normal"
              disabled
            />
            {/* Reference Code Dropdown */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Reference Code</InputLabel>
              <Select
                value={referenceCode}
                onChange={(e) => handleReferenceCodeSelect(e.target.value)}
                required
              >
                {referenceCodes.map((code, index) => (
                  <MenuItem key={index} value={code}>
                    {code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Rent Date Field */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Rent Date</InputLabel>
              <Select
                value={rentDate}
                onChange={(e) => setRentDate(e.target.value)}
                required
                disabled
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="15">15</MenuItem>
              </Select>
            </FormControl>

            {/* Bedroom Field */}
            <FormControl fullWidth margin="normal">
              <TextField
                fullWidth
                label="Bedroom"
                value={bedroom}
                onChange={(e) => setBedroom(e.target.value)}
                required
                disabled={bedroomLocked}
                margin="normal"
              />
              <FormControlLabel
                control={<Checkbox checked={!bedroomLocked} onChange={handleBedroomChange} />}
                label="Change Bedroom"
              />
            </FormControl>

            {/* Type Dropdown */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Type</InputLabel>
              <Select
                value={type}
                onChange={(e) => setType(e.target.value)}
                required
              >
                <MenuItem value="Single">Single</MenuItem>
                <MenuItem value="Double">Double</MenuItem>
                <MenuItem value="Twin">Twin</MenuItem>
              </Select>
            </FormControl>

            {/* Name Fields */}
            <TextField
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              margin="normal"
            />
            <TextField
              fullWidth
              label="Surname"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              required
              margin="normal"
            />

            {/* Rent and Deposit Fields */}
            <FormControl fullWidth margin="normal">
              <TextField
                fullWidth
                label="Rent"
                type="number"
                value={rent}
                onChange={(e) => setRent(e.target.value)}
                disabled={rentLocked}
                margin="normal"
              />
              <FormControlLabel
                control={<Checkbox checked={!rentLocked} onChange={handleRentChange} />}
                label="Change Rent"
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                fullWidth
                label="Deposit"
                type="number"
                value={deposit}
                onChange={(e) => setDeposit(e.target.value)}
                disabled={depositLocked}
                margin="normal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!depositLocked}
                    onChange={() => setDepositLocked(!depositLocked)}
                  />
                }
                label="Change Deposit"
              />
            </FormControl>

            {/* Move-in Date Field */}
            <TextField
              fullWidth
              label="Move-In Date"
              type="date"
              value={movedIn}
              onChange={(e) => setMovedIn(e.target.value)}
              required
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              fullWidth
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              margin="normal"
            />
            <TextField
              fullWidth
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              margin="normal"
            />

            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
              Add Licensee
            </Button>
          </form>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleGenerateContract}
            fullWidth
            disabled={loading}
            sx={{ mt: 2 }}
          >
            Generate Contract PDF
          </Button>
        </Box>
      </Paper>

      {/* Dialog for existing licensee replacement */}
      <Dialog open={dialogOpen} onClose={handleCancel}>
        <DialogTitle>Replace Existing Licensee</DialogTitle>
        <DialogContent>
          <DialogContentText>
            An existing licensee was found with this reference code.
            <br /><br />
            <strong>Old Licensee:</strong><br />
            Name: {existingLicensee?.NAME} {existingLicensee?.SURNAME}<br />
            Email: {existingLicensee?.EMAIL}<br />
            Phone: {existingLicensee?.['PHONE NUMBER']}<br />
            Rent: {existingLicensee?.RENT}<br />
            Deposit: {existingLicensee?.DEPOSIT}<br />
            Move-In Date: {existingLicensee?.['MOVED IN']}<br /><br />

            <strong>New Licensee:</strong><br />
            Name: {name} {surname}<br />
            Email: {email}<br />
            Phone: {phoneNumber}<br />
            Rent: {rent}<br />
            Deposit: {deposit}<br />
            Move-In Date: {movedIn}<br /><br />

            Would you like to replace the old licensee with the new one?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default LicenseeForm;
