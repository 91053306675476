import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  Paper,
  Link,
} from '@mui/material';
import { sendEmailVerification } from 'firebase/auth'; // Import sendEmailVerification

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, resetPassword } = useAuth(); // Remove currentUser from destructuring
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  // Handle login submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    try {
      const userCredential = await login(email, password);
      const user = userCredential.user;

      // Check if email is verified
      if (!user.emailVerified) {
        setError('Please verify your email before logging in.');
        await sendEmailVerification(user);
        return;
      }

      navigate('/');
    } catch (err) {
      console.error('Error during login:', err);
      setError('Failed to log in');
    }
  };

  // Handle password reset
  const handleForgotPassword = async () => {
    if (!email) {
      return setError('Please enter your email address to reset your password.');
    }
    try {
      setError('');
      setMessage('');
      await resetPassword(email);
      setMessage('Check your inbox for further instructions.');
    } catch (err) {
      setError('Failed to reset password');
    }
  };

  // Navigate to Register page
  const handleRegisterRedirect = () => {
    navigate('/register');
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3}>
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="body1" gutterBottom>
            Login
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          {message && <Alert severity="success">{message}</Alert>}
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              inputProps={{ 'data-testid': 'email-input' }}
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              inputProps={{ 'data-testid': 'password-input' }}
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Log In
            </Button>
          </form>
          <Box sx={{ mt: 2 }}>
            <Link href="#" onClick={handleForgotPassword}>
              Forgot Password?
            </Link>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={handleRegisterRedirect}
            >
              Register
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default Login;
