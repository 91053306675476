import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Autocomplete,
  Alert,
  Box,
  CircularProgress,
  Switch,
  FormControlLabel,
  Snackbar,
  Checkbox,
  FormGroup,
  FormControl
} from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { getStorage, ref, getDownloadURL, listAll, uploadBytes } from "firebase/storage";
import Papa from "papaparse";
import { getFunctions, httpsCallable } from 'firebase/functions';

function GenerateDepositLink() {
  const [manualEntry, setManualEntry] = useState(false);
  const [property, setProperty] = useState('');
  const [referenceCode, setReferenceCode] = useState('');
  const [depositAmount, setDepositAmount] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [paymentLink, setPaymentLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [referenceCodeOptions, setReferenceCodeOptions] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [sendEmail, setSendEmail] = useState(false); // New checkbox to send email

  useEffect(() => {
    console.log("Fetching property options on initial load...");
    const fetchOptions = async () => {
      try {
        const licenseesSnapshot = await getDocs(collection(db, 'licensees'));
        const licensees = licenseesSnapshot.docs.map((doc) => doc.data());

        console.log("Total licensees fetched:", licensees.length);

        const properties = Array.from(
          new Set(
            licensees
              .map((licensee) => {
                if (licensee['REFERENCE CODE'] && typeof licensee['REFERENCE CODE'] === 'string') {
                  return licensee['REFERENCE CODE'].replace(/\d+$/, '');
                }
                return null;
              })
              .filter(Boolean)
          )
        );

        console.log("Property options derived:", properties);
        setPropertyOptions(properties);

        if (properties.length > 0) {
          const initialProperty = properties[0];
          console.log("Initial property set to:", initialProperty);
          const initialReferenceCodes = licensees
            .filter((licensee) =>
              licensee['REFERENCE CODE'].startsWith(initialProperty)
            )
            .map((licensee) => licensee['REFERENCE CODE']);

          console.log("Initial reference codes derived:", initialReferenceCodes);
          setReferenceCodeOptions(initialReferenceCodes);
        }
      } catch (error) {
        console.error('Error fetching properties and reference codes:', error);
        setMessage('Failed to fetch properties and reference codes');
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchReferenceCodes = async () => {
      console.log("Fetching reference codes for property:", property);
      try {
        const licenseesSnapshot = await getDocs(collection(db, 'licensees'));
        const licensees = licenseesSnapshot.docs.map((doc) => doc.data());
        console.log("Total licensees:", licensees.length);

        const filteredReferenceCodes = licensees
          .filter((licensee) =>
            licensee['REFERENCE CODE'].startsWith(property)
          )
          .map((licensee) => licensee['REFERENCE CODE']);

        console.log("Filtered reference codes:", filteredReferenceCodes);
        setReferenceCodeOptions(filteredReferenceCodes);
      } catch (error) {
        console.error('Error fetching reference codes:', error);
      }
    };

    if (property) {
      fetchReferenceCodes();
    }
  }, [property]);

  useEffect(() => {
    const fetchDetails = async () => {
      if (manualEntry) {
        console.log("Manual entry mode is on, not fetching details from reference code.");
        return;
      }

      if (!referenceCode) {
        console.log("No reference code selected, skipping fetch details.");
        return;
      }

      console.log("Fetching details for selected reference code:", referenceCode);
      try {
        const licenseesSnapshot = await getDocs(collection(db, 'licensees'));
        const licensees = licenseesSnapshot.docs.map((doc) => doc.data());
        console.log("Total licensees:", licensees.length);

        const selectedLicensee = licensees.find(
          (licensee) => licensee['REFERENCE CODE'] === referenceCode
        );

        if (selectedLicensee) {
          console.log("Selected licensee found:", selectedLicensee);
          setAddress(selectedLicensee['ADDRESS'] || '');
          setEmail(selectedLicensee['EMAIL'] || '');
          setName(selectedLicensee['NAME'] || '');
          setPhone(selectedLicensee['PHONE NUMBER'] || '');
        } else {
          console.log("No licensee found for this reference code.");
        }
      } catch (error) {
        console.error('Error fetching details for selected reference code:', error);
      }
    };

    fetchDetails();
  }, [referenceCode, manualEntry]);

  const handleGenerate = async () => {
    console.log("Generate link button clicked.");
    console.log("Current form values:", {
      property,
      referenceCode,
      depositAmount,
      email,
      name,
      address,
      phone
    });

    if (
      !depositAmount ||
      !email ||
      !name ||
      !address ||
      !phone ||
      !property ||
      !referenceCode
    ) {
      console.warn("Missing required fields, cannot generate link.");
      setMessage('All fields are required. Please fill in all fields.');
      return;
    }

    // Check phone number for a '+' country code
    if (!phone.startsWith('+')) {
      console.warn("Phone number does not start with '+', cannot proceed.");
      setMessage('Please include the country code (e.g., +353) in the phone number.');
      return;
    }

    try {
      setLoading(true);
      setMessage('');
      setPaymentLink('');

      const payload = {
        property,
        referenceCode,
        depositAmount: Number(depositAmount),
        email,
        name,
        address,
        phone,
      };

      console.log('Payload sent to backend:', payload);

      const functionUrl = 'https://europe-west1-lobi-portal.cloudfunctions.net/generateDepositPaymentLink';

      console.log("Calling backend function to generate payment link:", functionUrl);
      const response = await axios.post(functionUrl, payload);

      const { paymentLinkUrl } = response.data;
      console.log("Payment link generated successfully:", paymentLinkUrl);
      setPaymentLink(paymentLinkUrl);
      setMessage('Payment link generated successfully!');

      // Extract Payment ID by removing the known prefix from the payment link
      const paymentId = paymentLinkUrl.replace("https://payment.truelayer.com/links/", "");

      // If the checkbox to send email is checked, send the email
      if (sendEmail) {
        console.log("Sending email notification as checkbox is selected...");
        await sendEmailNotification(payload, paymentLinkUrl);
      }

      console.log("Attempting to add a line to a CSV file for tracking...");
      await addLineToCSV(payload, paymentLinkUrl, paymentId);

    } catch (error) {
      console.error('Error generating payment link:', error);
      setMessage('Failed to generate payment link');
    } finally {
      setLoading(false);
    }
  };

  const sendEmailNotification = async (payload, linkUrl) => {
    try {
      console.log("Preparing to send email via Firebase Functions...");
      const functions = getFunctions(undefined, 'europe-west1'); 
      const sendReminderEmailCallable = httpsCallable(functions, 'sendReminderEmail');

      const subject = "Deposit Payment Link";
      const message = `Dear ${payload.name},\n\nPlease find your deposit payment link below:\n${linkUrl}\n\nRegards,\nThe Team`;

      console.log("Sending email with subject:", subject, "to:", payload.email);
      const response = await sendReminderEmailCallable({
        email: payload.email,
        subject: subject,
        message: message,
      });
      console.log("Email sent successfully:", response.data);

      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const addLineToCSV = async (payload, paymentLinkUrl, paymentId) => {
    const storage = getStorage();

    // Dynamically determine month and year
    const now = new Date();
    const currentMonthName = now.toLocaleString('en-US', { month: 'long' }).toUpperCase();
    const currentYear = now.getFullYear();
    const folderPath = `Open Banking Tracking/${currentYear}/${currentMonthName}`;
    const folderRef = ref(storage, folderPath);

    console.log("Listing files in folder:", folderPath);
    const periodFiles = await listAll(folderRef);
    console.log("Files found:", periodFiles.items.map(item => item.name));

    // The target file name should be current_month_1st_deposits.csv (month in uppercase)
    const targetFileName = `${currentMonthName}_1ST_deposits.csv`;
    const originalFile = periodFiles.items.find(item => item.name === targetFileName);

    // New headers as requested
    const headers = [
      'Name',
      'Ref Code',
      'Rent',
      'Email',
      'Phone Number',
      'Address',
      'MOVING OUT',
      'MOVED IN',
      'Payment Link',
      'Payment ID',
      'If link sent another time - new ID',
      'Amount on second link',
      'succeeded',
      'disabled',
      'date paid'
    ];

    // Create a new line object that matches these headers.
    // For the fields we don't have data for, we put empty strings.
    const newLine = {
      'Name': payload.name,
      'Ref Code': payload.referenceCode,
      'Rent': payload.depositAmount,
      'Email': payload.email,
      'Phone Number': payload.phone,
      'Address': payload.address,
      'MOVING OUT': '',
      'MOVED IN': '',
      'Payment Link': paymentLinkUrl,
      'Payment ID': paymentId,
      'If link sent another time - new ID': '',
      'Amount on second link': '',
      'succeeded': '',
      'disabled': '',
      'date paid': ''
    };

    try {
      let updatedCSV;

      if (!originalFile) {
        console.log(`CSV file (${targetFileName}) not found, creating a new one with new headers.`);
        // Create a new CSV with headers and one row
        updatedCSV = Papa.unparse({
          fields: headers,
          data: [Object.values(newLine)]
        });
      } else {
        console.log("Found existing CSV file:", originalFile.name);
        const url = await getDownloadURL(originalFile);
        console.log("Existing CSV file URL:", url);

        const response = await fetch(url);
        const csvText = await response.text();

        // Parse existing CSV
        const parsedResult = Papa.parse(csvText, { header: true });
        console.log("Parsed existing CSV. Rows:", parsedResult.data.length);

        // Append the new line
        const updatedData = [...parsedResult.data, newLine];
        updatedCSV = Papa.unparse(updatedData);
      }

      // Upload the CSV (either newly created or updated)
      const fileRef = ref(storage, `${folderPath}/${targetFileName}`);
      const blob = new Blob([updatedCSV], { type: 'text/csv' });
      console.log("Uploading CSV to:", `${folderPath}/${targetFileName}`);
      await uploadBytes(fileRef, blob);
      console.log("CSV uploaded successfully.");
    } catch (error) {
      console.error("Error appending/creating CSV:", error);
    }
  };

  return (
    <div>
      <h2>Generate Deposit Payment Link</h2>
      <FormControlLabel
        control={<Switch checked={manualEntry} onChange={() => {
          console.log("Toggling manual entry mode. Current value:", manualEntry);
          setManualEntry(!manualEntry);
        }} />}
        label="Manual Entry Mode"
      />
      {manualEntry ? (
        <>
          <TextField
            value={property}
            onChange={(e) => {
              console.log("Property changed to:", e.target.value);
              setProperty(e.target.value);
            }}
            label="Property"
            fullWidth
            margin="normal"
          />
          <TextField
            value={referenceCode}
            onChange={(e) => {
              console.log("Reference Code changed to:", e.target.value);
              setReferenceCode(e.target.value);
            }}
            label="Reference Code"
            fullWidth
            margin="normal"
          />
        </>
      ) : (
        <>
          <Box mb={2}>
            <Autocomplete
              options={propertyOptions}
              value={property}
              onChange={(e, newValue) => {
                console.log("Property selected from autocomplete:", newValue);
                setProperty(newValue || '');
              }}
              renderInput={(params) => <TextField {...params} label="Property" fullWidth />}
            />
          </Box>
          <Box mb={2}>
            <Autocomplete
              options={referenceCodeOptions}
              value={referenceCode}
              onChange={(e, newValue) => {
                console.log("Reference Code selected from autocomplete:", newValue);
                setReferenceCode(newValue || '');
              }}
              renderInput={(params) => <TextField {...params} label="Reference Code" fullWidth />}
            />
          </Box>
        </>
      )}
      <TextField 
        value={name} 
        onChange={(e) => {
          console.log("Name changed to:", e.target.value);
          setName(e.target.value);
        }} 
        label="Name" 
        fullWidth 
        margin="normal" 
      />
      <TextField 
        value={email} 
        onChange={(e) => {
          console.log("Email changed to:", e.target.value);
          setEmail(e.target.value);
        }} 
        label="Email" 
        fullWidth 
        margin="normal" 
      />
      <TextField 
        value={phone} 
        onChange={(e) => {
          console.log("Phone changed to:", e.target.value);
          setPhone(e.target.value);
        }} 
        label="Phone" 
        helperText="Please include country code, e.g. +353 for Ireland" 
        fullWidth 
        margin="normal" 
      />
      <TextField 
        value={address} 
        onChange={(e) => {
          console.log("Address changed to:", e.target.value);
          setAddress(e.target.value);
        }} 
        label="Address" 
        fullWidth 
        margin="normal" 
      />
      <TextField
        value={depositAmount}
        onChange={(e) => {
          console.log("Deposit Amount changed to:", e.target.value);
          setDepositAmount(e.target.value);
        }}
        label="Deposit Amount"
        type="number"
        fullWidth
        margin="normal"
      />

      <FormControl component="fieldset" style={{ marginTop: '16px' }}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={sendEmail} onChange={() => setSendEmail(!sendEmail)} />}
            label="Send Email Notification"
          />
        </FormGroup>
      </FormControl>

      <Box mt={2}>
        <Button onClick={handleGenerate} disabled={loading} variant="contained" color="primary">
          {loading ? <CircularProgress size={24} /> : 'Generate Link'}
        </Button>
      </Box>
      {message && (
        <Box mt={2}>
          <Alert severity={paymentLink ? 'success' : 'error'}>{message}</Alert>
        </Box>
      )}
      {paymentLink && (
        <Box mt={2}>
          <a href={paymentLink} target="_blank" rel="noreferrer">
            Open Payment Link
          </a>
        </Box>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="success" onClose={() => setSnackbarOpen(false)}>
          Email Sent Successfully!
        </Alert>
      </Snackbar>
    </div>
  );
}

export default GenerateDepositLink;
