import React, { useContext, useState, useEffect, createContext } from 'react';
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
} from 'firebase/firestore';
import { auth, db } from '../firebase';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to approve user if email is verified and email matches licensee
  const approveUserIfEligible = async (user) => {
    console.log('Starting approveUserIfEligible function...');
    console.log('User object:', user);

    if (user.emailVerified) {
      console.log('Email is verified for user:', user.email);

      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log('User data from Firestore:', userData);

        // Check if user is already approved
        if (userData.status === 'approved') {
          console.log('User is already approved. No further action needed.');
          return;
        }

        // Search for matching licensee by email
        console.log('Searching for matching licensee by email:', user.email.toLowerCase());
        const licenseeQuery = query(
          collection(db, 'licensees'),
          where('EMAIL', '==', user.email.toLowerCase())
        );
        const licenseeSnapshot = await getDocs(licenseeQuery);

        if (!licenseeSnapshot.empty) {
          console.log('Matching licensee found!');
          const licenseeDoc = licenseeSnapshot.docs[0].data();
          console.log('Licensee data:', licenseeDoc);

          const referenceCode = licenseeDoc['REFERENCE CODE'];
          console.log('Assigning reference code to user:', referenceCode);

          // Update user document with reference code and approve the user
          await updateDoc(userRef, {
            referenceCode,
            status: 'approved',
          });

          console.log('User document updated with reference code and status set to approved.');

          setUserStatus('approved');
          setUserRole('user');
          console.log('User status and role updated in local state.');
        } else {
          console.log('No matching licensee found for email:', user.email.toLowerCase());
        }
      } else {
        console.log('No user document found in Firestore for UID:', user.uid);
      }
    } else {
      console.log('Email is not verified for user:', user.email);
    }

    console.log('approveUserIfEligible function completed.');
  };

  useEffect(() => {
    let unsubscribeUserDoc = null; // Declare unsubscribe function
    let intervalId = null; // Declare interval ID for periodic checks
  
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      console.log('onAuthStateChanged triggered. User:', user);
  
      if (user) {
        setCurrentUser(user);
  
        const userRef = doc(db, 'users', user.uid);
  
        // Check if user document exists
        const userDoc = await getDoc(userRef);
        if (!userDoc.exists()) {
          console.log('User document does not exist. Creating one.');
          // Create user document
          await setDoc(userRef, {
            email: user.email,
            emailVerified: user.emailVerified,
            status: 'pending',
            role: 'user',
          });
        } else {
          console.log('User document exists:', userDoc.data());
        }
  
        // Set up Firestore listener for the user's document
        unsubscribeUserDoc = onSnapshot(
          userRef,
          async (docSnapshot) => {
            console.log('onSnapshot triggered for user document:', docSnapshot.data());
            const userData = docSnapshot.data();
            if (userData) {
              setUserRole(userData.role);
              setUserStatus(userData.status);
  
              // If user status is 'pending', set up periodic email verification check
              if (userData.status === 'pending' && !intervalId) {
                console.log('Setting up periodic email verification check for pending user.');
  
                intervalId = setInterval(async () => {
                  console.log('Running periodic email verification check...');
                  await user.reload(); // Reload the user's authentication data
                  const updatedUser = auth.currentUser; // Get the updated user object
  
                  console.log('Updated emailVerified status:', updatedUser.emailVerified);
  
                  // If the email is verified, update Firestore and approve the user
                  if (updatedUser.emailVerified) {
                    console.log('Email verified. Attempting to approve user...');
                    await updateDoc(userRef, { emailVerified: true });
                    await approveUserIfEligible(updatedUser);
                    clearInterval(intervalId); // Stop further checks
                    intervalId = null; // Reset interval ID
                  }
                }, 20000); // Run the check every 30 seconds
              }
            }
          },
          (error) => {
            console.error('Error in onSnapshot:', error);
          }
        );
  
      } else {
        setCurrentUser(null);
        setUserRole(null);
        setUserStatus(null);
        if (unsubscribeUserDoc) {
          unsubscribeUserDoc(); // Unsubscribe from user doc listener
          unsubscribeUserDoc = null; // Reset the unsubscribe function
        }
        if (intervalId) {
          clearInterval(intervalId); // Clear the periodic check
          intervalId = null; // Reset interval ID
        }
      }
      setLoading(false);
    });
  
    // Cleanup function for the useEffect hook
    return () => {
      unsubscribeAuth(); // Unsubscribe from onAuthStateChanged
      if (unsubscribeUserDoc) {
        unsubscribeUserDoc(); // Unsubscribe from onSnapshot
      }
      if (intervalId) {
        clearInterval(intervalId); // Clear the periodic check
      }
    };
  }, []);
  
  // Function to handle signup
  const signup = async (email, password) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Create user document in Firestore
    const userRef = doc(db, 'users', user.uid);
    await setDoc(userRef, {
      email: user.email,
      emailVerified: user.emailVerified,
      status: 'pending',
      role: 'user',
    });

    return user;
  };

  // Function to handle login
  const login = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    return userCredential;
  };

  // Function to handle password reset
  const resetPassword = async (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  // Function to handle logout
  const logout = () => {
    return signOut(auth);
  };

  // Auth context value passed to the components
  const value = {
    currentUser,
    userRole,
    userStatus,
    signup,
    login,
    logout,
    resetPassword,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
