import React, { useState } from "react";
import {
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import "./InvestmentSimulator.css";

const InvestmentSimulator = () => {
  const [propertyPrice, setPropertyPrice] = useState(400000); // Default property price
  const [mortgagePercentage, setMortgagePercentage] = useState(90); // Default mortgage percentage
  const [mortgageRate, setMortgageRate] = useState(3.7); // Default interest rate
  const [returnPercentage, setReturnPercentage] = useState(15); // Default % return
  const [loanTerm, setLoanTerm] = useState(29); // Default loan term in years
  const [currentYear, setCurrentYear] = useState(1); // Current year of the term

  const isMobile = useMediaQuery("(max-width: 768px)"); // Detect if on mobile

  // Calculated mortgage amount
  const mortgageAmount = (propertyPrice * mortgagePercentage) / 100;

  // Constants for calculations
  const yearlyRentalIncome = (propertyPrice * returnPercentage) / 100;
  const monthlyRentalIncome = yearlyRentalIncome / 12;

  const mortgageMonthlyRate = mortgageRate / 100 / 12;
  const numberOfPayments = loanTerm * 12;
  const monthlyMortgage =
    (mortgageAmount * mortgageMonthlyRate) /
    (1 - Math.pow(1 + mortgageMonthlyRate, -numberOfPayments));

  // Corrected amortization logic
  const calculateYearlyInterestAndPrincipal = (year) => {
    let remainingBalance = mortgageAmount;
    let yearlyInterest = 0;
    let yearlyPrincipal = 0;

    const startMonth = (year - 1) * 12 + 1;
    const endMonth = year * 12;

    let monthlyBreakdown = [];

    for (let month = 1; month <= numberOfPayments; month++) {
      const interestPayment = remainingBalance * mortgageMonthlyRate;
      const principalPayment = monthlyMortgage - interestPayment;

      if (month >= startMonth && month <= endMonth) {
        yearlyInterest += interestPayment;
        yearlyPrincipal += principalPayment;
        monthlyBreakdown.push({
          month,
          interestPayment: interestPayment.toFixed(2),
          principalPayment: principalPayment.toFixed(2),
          remainingBalance: (remainingBalance - principalPayment).toFixed(2),
        });
      }

      remainingBalance -= principalPayment;

      if (remainingBalance <= 0) break;
    }

    return { yearlyInterest, yearlyPrincipal, monthlyBreakdown };
  };

  // Get yearly breakdown for the selected year
  const {
    yearlyInterest,
    yearlyPrincipal,
    monthlyBreakdown,
  } = calculateYearlyInterestAndPrincipal(currentYear);

  const monthlyInterestForYear = yearlyInterest / 12;
  const monthlyPrincipalForYear = yearlyPrincipal / 12;

  // Taxable income calculations
  const yearlyTaxableProfit = yearlyRentalIncome - yearlyInterest;
  const monthlyTaxableProfit = yearlyTaxableProfit / 12;

  // Irish Tax Rules 2024
  const calculateAfterTaxProfit = (income, existingIncome = 0) => {
    let incomeTax = 0;
    let prsi = 0;
    let usc = 0;

    const totalIncome = existingIncome + income;

    // Income Tax Calculation
    const taxBands = [
      { limit: 40800, rate: 0.2 },
      { limit: Infinity, rate: 0.4 },
    ];

    let remainingIncome = totalIncome;
    let accumulatedTax = 0;
    for (const band of taxBands) {
      const taxableAtThisRate = Math.min(band.limit, remainingIncome);
      accumulatedTax += taxableAtThisRate * band.rate;
      remainingIncome -= taxableAtThisRate;
      if (remainingIncome <= 0) break;
    }

    const existingIncomeTax = (() => {
      let remIncome = existingIncome;
      let tax = 0;
      for (const band of taxBands) {
        const taxable = Math.min(band.limit, remIncome);
        tax += taxable * band.rate;
        remIncome -= taxable;
        if (remIncome <= 0) break;
      }
      return tax;
    })();

    incomeTax = accumulatedTax - existingIncomeTax;

    // PRSI Calculation (4% on total income over €352/week)
    prsi = income * 0.04;

    // USC Calculation
    const uscBands = [
      { limit: 12012, rate: 0.005 },
      { limit: 22920, rate: 0.02 },
      { limit: 70144, rate: 0.045 },
      { limit: Infinity, rate: 0.08 },
    ];

    remainingIncome = totalIncome;
    let accumulatedUSC = 0;
    for (const band of uscBands) {
      const taxableAtThisRate = Math.min(band.limit, remainingIncome);
      accumulatedUSC += taxableAtThisRate * band.rate;
      remainingIncome -= taxableAtThisRate;
      if (remainingIncome <= 0) break;
    }

    const existingUSC = (() => {
      let remIncome = existingIncome;
      let uscTax = 0;
      for (const band of uscBands) {
        const taxable = Math.min(band.limit, remIncome);
        uscTax += taxable * band.rate;
        remIncome -= taxable;
        if (remIncome <= 0) break;
      }
      return uscTax;
    })();

    usc = accumulatedUSC - existingUSC;

    const taxCredits = existingIncome === 0 ? 1775 : 0; // Personal tax credit only if no existing income

    const totalTaxOwed = incomeTax + prsi + usc - taxCredits;
    const afterTaxIncome = income - Math.max(totalTaxOwed, 0);

    return {
      afterTaxIncome,
      incomeTax,
      prsi,
      usc,
      taxCredits,
      totalTaxOwed,
    };
  };

  // After-Tax Profit with full calculation
  const afterTaxProfitDetails = calculateAfterTaxProfit(yearlyTaxableProfit);

  // After-Tax Profit assuming existing income of €50,000
  const existingIncome = 50000;
  const afterTaxProfitHighDetails = calculateAfterTaxProfit(
    yearlyTaxableProfit,
    existingIncome
  );

  const yearlyAfterTaxProfit = afterTaxProfitDetails.afterTaxIncome;
  const monthlyAfterTaxProfit = yearlyAfterTaxProfit / 12;

  const yearlyAfterTaxProfitHigh = afterTaxProfitHighDetails.afterTaxIncome;
  const monthlyAfterTaxProfitHigh = yearlyAfterTaxProfitHigh / 12;

  // Net Remaining Profits
  const netRemainingProfitStandardYearly =
    yearlyAfterTaxProfit - yearlyPrincipal;
  const netRemainingProfitStandardMonthly = netRemainingProfitStandardYearly / 12;

  const netRemainingProfitHighTaxYearly =
    yearlyAfterTaxProfitHigh - yearlyPrincipal;
  const netRemainingProfitHighTaxMonthly = netRemainingProfitHighTaxYearly / 12;

  const [expandedRows, setExpandedRows] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const handleRowClick = (key, details) => {
    if (isMobile) {
      // Open dialog with details
      setDialogContent(details);
      setDialogOpen(true);
    } else {
      // Toggle expansion in desktop view
      setExpandedRows((prev) => ({
        ...prev,
        [key]: !prev[key],
      }));
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDialogContent(null);
  };

  // Prepare metrics for the table
  const metrics = [
    {
      key: "rentalIncome",
      displayName: "Rental Income",
      yearlyValue: yearlyRentalIncome.toFixed(2),
      monthlyValue: monthlyRentalIncome.toFixed(2),
      details: (
        <div>
          <p>Yearly Rental Income = (Property Price * Return %) / 100</p>
          <p>
            = ({propertyPrice} € * {returnPercentage}%) / 100
          </p>
          <p>= {yearlyRentalIncome.toFixed(2)} €</p>
          <p>Monthly Rental Income = Yearly Rental Income / 12</p>
          <p>= {yearlyRentalIncome.toFixed(2)} € / 12</p>
          <p>= {monthlyRentalIncome.toFixed(2)} €</p>
        </div>
      ),
    },
    {
      key: "mortgagePayment",
      displayName: "Mortgage Payment",
      yearlyValue: (monthlyMortgage * 12).toFixed(2),
      monthlyValue: monthlyMortgage.toFixed(2),
      details: (
        <div>
          <p>Mortgage Monthly Rate = Mortgage Rate / 100 / 12</p>
          <p>
            = {mortgageRate}% / 100 / 12 = {mortgageMonthlyRate.toFixed(6)}
          </p>
          <p>Number of Payments = Loan Term * 12</p>
          <p>
            = {loanTerm} * 12 = {numberOfPayments}
          </p>
          <p>
            Monthly Mortgage Payment = [Mortgage Amount * Mortgage Monthly Rate]
            / [1 - (1 + Mortgage Monthly Rate)^(-Number of Payments)]
          </p>
          <p>
            = [{mortgageAmount.toFixed(2)} € * {mortgageMonthlyRate.toFixed(6)}]
            / [1 - (1 + {mortgageMonthlyRate.toFixed(6)})^(-{numberOfPayments})]
          </p>
          <p>= {monthlyMortgage.toFixed(2)} €</p>
        </div>
      ),
    },
    {
      key: "interest",
      displayName: `Interest (Year ${currentYear})`,
      yearlyValue: yearlyInterest.toFixed(2),
      monthlyValue: monthlyInterestForYear.toFixed(2),
      details: (
        <div>
          <p>
            Interest is calculated monthly based on the remaining balance. Here
            is the breakdown for Year {currentYear}:
          </p>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ border: "1px solid #ddd", padding: "4px" }}>
                  Month
                </th>
                <th style={{ border: "1px solid #ddd", padding: "4px" }}>
                  Interest (€)
                </th>
                <th style={{ border: "1px solid #ddd", padding: "4px" }}>
                  Principal (€)
                </th>
                <th style={{ border: "1px solid #ddd", padding: "4px" }}>
                  Remaining Balance (€)
                </th>
              </tr>
            </thead>
            <tbody>
              {monthlyBreakdown.map((item) => (
                <tr key={item.month}>
                  <td style={{ border: "1px solid #ddd", padding: "4px" }}>
                    {item.month}
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "4px" }}>
                    {item.interestPayment}
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "4px" }}>
                    {item.principalPayment}
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "4px" }}>
                    {item.remainingBalance}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <p>Total Yearly Interest: {yearlyInterest.toFixed(2)} €</p>
          <p>Total Yearly Principal: {yearlyPrincipal.toFixed(2)} €</p>
        </div>
      ),
    },
    {
      key: "principal",
      displayName: `Principal (Year ${currentYear})`,
      yearlyValue: yearlyPrincipal.toFixed(2),
      monthlyValue: monthlyPrincipalForYear.toFixed(2),
      details: (
        <div>
          <p>
            Principal repayment reduces the mortgage balance. See the Interest
            section for detailed breakdown.
          </p>
          <p>Total Yearly Principal: {yearlyPrincipal.toFixed(2)} €</p>
          <p>Monthly Principal: {monthlyPrincipalForYear.toFixed(2)} €</p>
        </div>
      ),
    },
    {
      key: "taxableProfit",
      displayName: "Taxable Profit (Income - Interest)",
      yearlyValue: yearlyTaxableProfit.toFixed(2),
      monthlyValue: monthlyTaxableProfit.toFixed(2),
      details: (
        <div>
          <p>Yearly Taxable Profit = Yearly Rental Income - Yearly Interest</p>
          <p>
            = {yearlyRentalIncome.toFixed(2)} € - {yearlyInterest.toFixed(2)} €
          </p>
          <p>= {yearlyTaxableProfit.toFixed(2)} €</p>
          <p>Monthly Taxable Profit = {yearlyTaxableProfit.toFixed(2)} € / 12</p>
          <p>= {monthlyTaxableProfit.toFixed(2)} €</p>
        </div>
      ),
    },
    {
      key: "afterTaxProfitFull",
      displayName: "After-Tax Profit (No Existing Income)",
      yearlyValue: yearlyAfterTaxProfit.toFixed(2),
      monthlyValue: monthlyAfterTaxProfit.toFixed(2),
      details: (
        <div>
          <p>
            Assuming no existing income, the taxes are calculated as follows:
          </p>
          <p>Income Tax: {afterTaxProfitDetails.incomeTax.toFixed(2)} €</p>
          <p>PRSI: {afterTaxProfitDetails.prsi.toFixed(2)} €</p>
          <p>USC: {afterTaxProfitDetails.usc.toFixed(2)} €</p>
          <p>Tax Credits: -{afterTaxProfitDetails.taxCredits.toFixed(2)} €</p>
          <p>
            Total Tax Owed: {afterTaxProfitDetails.totalTaxOwed.toFixed(2)} €
          </p>
          <p>
            After-Tax Income = Yearly Taxable Profit - Total Tax Owed
          </p>
          <p>
            = {yearlyTaxableProfit.toFixed(2)} € -{" "}
            {afterTaxProfitDetails.totalTaxOwed.toFixed(2)} €
          </p>
          <p>= {yearlyAfterTaxProfit.toFixed(2)} €</p>
        </div>
      ),
    },
    {
      key: "afterTaxProfitHigh",
      displayName: "After-Tax Profit (Existing Income €50,000)",
      yearlyValue: yearlyAfterTaxProfitHigh.toFixed(2),
      monthlyValue: monthlyAfterTaxProfitHigh.toFixed(2),
      details: (
        <div>
          <p>
            Assuming existing income of €50,000, the rental income is taxed at
            higher rates:
          </p>
          <p>Additional Income Tax: {afterTaxProfitHighDetails.incomeTax.toFixed(2)} €</p>
          <p>Additional PRSI: {afterTaxProfitHighDetails.prsi.toFixed(2)} €</p>
          <p>Additional USC: {afterTaxProfitHighDetails.usc.toFixed(2)} €</p>
          <p>Tax Credits: {afterTaxProfitHighDetails.taxCredits.toFixed(2)} €</p>
          <p>
            Total Additional Tax Owed: {afterTaxProfitHighDetails.totalTaxOwed.toFixed(2)} €
          </p>
          <p>
            After-Tax Income = Yearly Taxable Profit - Total Additional Tax Owed
          </p>
          <p>
            = {yearlyTaxableProfit.toFixed(2)} € -{" "}
            {afterTaxProfitHighDetails.totalTaxOwed.toFixed(2)} €
          </p>
          <p>= {yearlyAfterTaxProfitHigh.toFixed(2)} €</p>
        </div>
      ),
    },
    {
      key: "netProfitFull",
      displayName: "Net Profit (No Existing Income)",
      yearlyValue: netRemainingProfitStandardYearly.toFixed(2),
      monthlyValue: netRemainingProfitStandardMonthly.toFixed(2),
      details: (
        <div>
          <p>Net Profit = After-Tax Profit - Yearly Principal Payment</p>
          <p>
            = {yearlyAfterTaxProfit.toFixed(2)} € -{" "}
            {yearlyPrincipal.toFixed(2)} €
          </p>
          <p>= {netRemainingProfitStandardYearly.toFixed(2)} €</p>
          <p>
            Monthly Net Profit = {netRemainingProfitStandardYearly.toFixed(2)} €
            / 12
          </p>
          <p>= {netRemainingProfitStandardMonthly.toFixed(2)} €</p>
        </div>
      ),
    },
    {
      key: "netProfitHigh",
      displayName: "Net Profit (Existing Income €50,000)",
      yearlyValue: netRemainingProfitHighTaxYearly.toFixed(2),
      monthlyValue: netRemainingProfitHighTaxMonthly.toFixed(2),
      details: (
        <div>
          <p>Net Profit = After-Tax Profit - Yearly Principal Payment</p>
          <p>
            = {yearlyAfterTaxProfitHigh.toFixed(2)} € -{" "}
            {yearlyPrincipal.toFixed(2)} €
          </p>
          <p>= {netRemainingProfitHighTaxYearly.toFixed(2)} €</p>
          <p>
            Monthly Net Profit = {netRemainingProfitHighTaxYearly.toFixed(2)} €
            / 12
          </p>
          <p>= {netRemainingProfitHighTaxMonthly.toFixed(2)} €</p>
        </div>
      ),
    },
  ];

  return (
    <div className={`card-container ${isMobile ? "mobile-layout" : ""}`}>
      <h2 className="card-title">Investment Simulator</h2>
      <div className="card-content">
        {/* Sliders Section */}
        <div
          style={{
            display: isMobile ? "block" : "grid",
            gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
            gap: "20px",
          }}
        >
          <div>
            <label>Property Price (€): {propertyPrice}</label>
            <input
              type="range"
              min="50000"
              max="1000000"
              step="10000"
              value={propertyPrice}
              onChange={(e) => setPropertyPrice(Number(e.target.value))}
            />
          </div>
          <div>
            <label>
              Mortgage Amount: {mortgagePercentage}% (€{mortgageAmount.toFixed(2)}
              )
            </label>
            <input
              type="range"
              min="10"
              max="100"
              step="1"
              value={mortgagePercentage}
              onChange={(e) => setMortgagePercentage(Number(e.target.value))}
            />
          </div>
          <div>
            <label>Mortgage Interest Rate (%): {mortgageRate}</label>
            <input
              type="range"
              min="1"
              max="10"
              step="0.1"
              value={mortgageRate}
              onChange={(e) => setMortgageRate(Number(e.target.value))}
            />
          </div>
          <div>
            <label>Return Percentage (%): {returnPercentage}</label>
            <input
              type="range"
              min="2"
              max="25"
              step="1"
              value={returnPercentage}
              onChange={(e) => setReturnPercentage(Number(e.target.value))}
            />
          </div>
          <div>
            <label>Loan Term (years): {loanTerm}</label>
            <input
              type="range"
              min="5"
              max="35"
              step="1"
              value={loanTerm}
              onChange={(e) => setLoanTerm(Number(e.target.value))}
            />
          </div>
          <div>
            <label>Current Year of Term: {currentYear}</label>
            <input
              type="range"
              min="1"
              max={loanTerm}
              step="1"
              value={currentYear}
              onChange={(e) => setCurrentYear(Number(e.target.value))}
            />
          </div>
        </div>

        {/* Results Section */}
        <div>
          <h3>Results:</h3>
          <div style={{ overflowX: "auto" }}>
            <table
              style={{
                width: "100%",
                fontSize: "14px",
                textAlign: "left",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: "8px",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    Metric
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      borderBottom: "1px solid #ddd",
                      textAlign: "right",
                    }}
                  >
                    Yearly (€)
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      borderBottom: "1px solid #ddd",
                      textAlign: "right",
                    }}
                  >
                    Monthly (€)
                  </th>
                </tr>
              </thead>
              <tbody>
                {metrics.map((metric) => (
                  <React.Fragment key={metric.key}>
                    <tr
                      onClick={() => handleRowClick(metric.key, metric.details)}
                      style={{
                        cursor: "pointer",
                        backgroundColor: expandedRows[metric.key]
                          ? "#f9f9f9"
                          : "transparent",
                      }}
                    >
                      <td style={{ padding: "8px" }}>{metric.displayName}</td>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "right",
                        }}
                      >
                        {metric.yearlyValue}
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          textAlign: "right",
                        }}
                      >
                        {metric.monthlyValue}
                      </td>
                    </tr>
                    {!isMobile && expandedRows[metric.key] && (
                      <tr>
                        <td colSpan="3" style={{ padding: "8px" }}>
                          {metric.details}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Dialog for mobile view */}
      {isMobile && (
        <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth>
          <DialogTitle>Calculation Details</DialogTitle>
          <DialogContent>{dialogContent}</DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default InvestmentSimulator;
