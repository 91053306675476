import React, { useState, useEffect, useCallback } from 'react';
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import Papa from "papaparse";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { 
  Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, 
  Accordion, AccordionSummary, AccordionDetails, TextField, MenuItem, 
  FormControl, Select, InputLabel, Button, ButtonGroup, Snackbar, Alert, 
  Card, CardContent, CardActions, Dialog, DialogTitle, DialogContent, 
  DialogActions, CircularProgress 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getFunctions, httpsCallable } from "firebase/functions"; 
import './AdminRentPage.css';

const sendReminderEmail = async (licensee, setSnackbarState) => {
  try {
    const functions = getFunctions(undefined, 'europe-west1');
    const sendReminderEmailCallable = httpsCallable(functions, 'sendReminderEmail');

    await sendReminderEmailCallable({
      email: licensee.EMAIL,
      subject: "Rent Reminder",
      message: `Dear ${licensee.NAME},\n\nThis is a friendly reminder that your rent is due. Please make your payment as soon as possible.\n\nRegards,\nThe Team`,
    });
    setSnackbarState({ open: true, message: `Reminder sent to ${licensee.EMAIL}`, severity: "success" });
  } catch (error) {
    console.error("Error sending email:", error);
    setSnackbarState({ open: true, message: `Failed to send email to ${licensee.EMAIL}`, severity: "error" });
  }
};

const fetchAvailablePeriods = async () => {
  const storage = getStorage();
  const baseRef = ref(storage, 'Open Banking Tracking/');
  const availablePeriods = {};

  try {
    const yearFolders = await listAll(baseRef);
    for (const yearFolder of yearFolders.prefixes) {
      const year = yearFolder.name;
      if (/^\d{4}$/.test(year)) {
        availablePeriods[year] = {};
        const monthFoldersRef = ref(storage, `Open Banking Tracking/${year}`);
        const monthFolders = await listAll(monthFoldersRef);

        for (const monthFolder of monthFolders.prefixes) {
          const month = monthFolder.name.toUpperCase();
          availablePeriods[year][month] = [];
          const periodFilesRef = ref(storage, `Open Banking Tracking/${year}/${month}`);
          const periodFiles = await listAll(periodFilesRef);

          // Only consider files containing PAYMENT_LINKS
          const paymentLinkFiles = periodFiles.items.filter(item => item.name.includes('PAYMENT_LINKS'));
          paymentLinkFiles.forEach(file => {
            const period = file.name.replace('.csv', '').split('_')[1].toUpperCase();
            availablePeriods[year][month].push(period);
          });
        }
      }
    }
  } catch (error) {
    console.error("Error fetching available periods from Firebase:", error);
  }

  return availablePeriods;
};

const hasPaidRent = (licensee, paymentData) => {
  const referenceCode = licensee['REFERENCE CODE'];
  const matchingPayment = paymentData.find(payment => payment['Ref Code'] === referenceCode);
  return matchingPayment && matchingPayment['succeeded'] === 'succeeded';
};

const getPaymentDate = (licensee, paymentData) => {
  const referenceCode = licensee['REFERENCE CODE'];
  const matchingPayment = paymentData.find(payment => 
    payment['Ref Code'] === referenceCode && 
    payment['succeeded'] === 'succeeded'
  );
  return matchingPayment ? matchingPayment['date paid'] : '';
};

const extractPropertyCode = (referenceCode) => {
  return referenceCode.replace(/\d+$/, '');
};

const AdminRentPage = () => {
  const [licensees, setLicensees] = useState({});
  const [filteredLicensees, setFilteredLicensees] = useState({});
  const [paymentData, setPaymentData] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [availablePeriods, setAvailablePeriods] = useState({});
  const [availablePropertyCodes, setAvailablePropertyCodes] = useState([]);
  const [loadingPeriods, setLoadingPeriods] = useState(true);
  const [expandedHouse, setExpandedHouse] = useState(null);
  const [totalDue, setTotalDue] = useState(0);
  const [totalReceived, setTotalReceived] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const [snackbarState, setSnackbarState] = useState({ open: false, message: '', severity: '' });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [selectedTenantHistory, setSelectedTenantHistory] = useState([]);
  const [selectedTenantName, setSelectedTenantName] = useState('');

  // Loading states
  const [mainLoading, setMainLoading] = useState(false);       // For main page loading (fetching CSV)
  const [historyLoading, setHistoryLoading] = useState(false); // For loading the full payment history

  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  useEffect(() => {
    const fetchHouseList = async () => {
      try {
        const licenseeCollection = collection(db, 'licensees');
        const licenseeSnapshot = await getDocs(licenseeCollection);
        const propertyCodes = [...new Set(licenseeSnapshot.docs.map(doc => extractPropertyCode(doc.data()['REFERENCE CODE'])))];
        setAvailablePropertyCodes(propertyCodes);

        const houseList = licenseeSnapshot.docs.reduce((acc, doc) => {
          const data = doc.data();
          const propertyCode = extractPropertyCode(data['REFERENCE CODE']);
          if (!acc[propertyCode]) acc[propertyCode] = [];
          acc[propertyCode].push({ id: doc.id, ...data });
          return acc;
        }, {});
        setLicensees(houseList);
        setFilteredLicensees(houseList);
      } catch (error) {
        console.error("Error fetching house list from Firestore:", error);
      }
    };
    fetchHouseList();
  }, []);

  const getCurrentPaymentRun = useCallback(() => {
    const now = new Date();
    const day = now.getDate();
    return day >= 13 && day <= 27 ? "15TH" : "1ST"; 
  }, []);

  useEffect(() => {
    const fetchPeriods = async () => {
      setLoadingPeriods(true);
      const periods = await fetchAvailablePeriods();
      setAvailablePeriods(periods);

      const sortedYears = Object.keys(periods).sort((a, b) => b - a);
      const recentYear = sortedYears[0];

      if (recentYear) {
        const sortedMonths = Object.keys(periods[recentYear]).sort((a, b) => {
          const monthOrder = { 
            "JANUARY": 1, "FEBRUARY": 2, "MARCH": 3, "APRIL": 4, "MAY": 5, "JUNE": 6, 
            "JULY": 7, "AUGUST": 8, "SEPTEMBER": 9, "OCTOBER": 10, "NOVEMBER": 11, "DECEMBER": 12 
          };
          return monthOrder[b] - monthOrder[a];
        });
        const recentMonth = sortedMonths[0];

        if (recentMonth && periods[recentYear][recentMonth].length > 0) {
          const recentPeriod = getCurrentPaymentRun();
          setSelectedYear(recentYear);
          setSelectedMonth(recentMonth);
          setSelectedPeriod(recentPeriod);
          fetchCSVFromFirebase(recentYear, recentMonth, recentPeriod);
        }
      }
      setLoadingPeriods(false);
    };

    fetchPeriods();
  }, [getCurrentPaymentRun]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (selectedYear && selectedMonth && selectedPeriod) {
      fetchCSVFromFirebase(selectedYear, selectedMonth, selectedPeriod);
    }
  }, [selectedYear, selectedMonth, selectedPeriod]);

  const fetchCSVFromFirebase = async (year, month, period) => {
    setMainLoading(true);
    const storage = getStorage();
    const folderPath = `Open Banking Tracking/${year}/${month}`;
    const folderRef = ref(storage, folderPath);

    try {
      const periodFiles = await listAll(folderRef);
      const matchingFile = periodFiles.items.find(item => item.name.includes(`_${period}_PAYMENT_LINKS.csv`));
      if (matchingFile) {
        const url = await getDownloadURL(matchingFile);
        const response = await fetch(url);
        const csvText = await response.text();
        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            if (result.data.length > 0) {
              calculateTotals(result.data);
              setPaymentData(result.data);
            } else {
              setPaymentData([]);
              setTotalDue(0);
              setTotalReceived(0);
            }
          },
          error: () => {
            setPaymentData([]);
            setTotalDue(0);
            setTotalReceived(0);
          }
        });
      } else {
        setPaymentData([]);
        setTotalDue(0);
        setTotalReceived(0);
      }
    } catch (error) {
      console.error("Failed to fetch CSV from Firebase:", error);
      setPaymentData([]);
      setTotalDue(0);
      setTotalReceived(0);
    }
    setMainLoading(false);
  };

  const calculateTotals = (parsedData) => {
    let rentDue = 0;
    let rentReceived = 0;
    parsedData.forEach(row => {
      const rent = parseFloat(row['Rent']) || 0;
      rentDue += rent;
      if (row['succeeded'] === "succeeded") rentReceived += rent;
    });
    setTotalDue(rentDue);
    setTotalReceived(rentReceived);
  };

  // Convert selectedPeriod ("1ST" or "15TH") to a day number string ("1" or "15") for filtering
  const getPeriodDay = () => {
    return selectedPeriod === "1ST" ? "1" : (selectedPeriod === "15TH" ? "15" : "");
  };

  useEffect(() => {
    // After applying search and filter, also filter by RENT DATE matching the selectedPeriod
    const dayFilter = getPeriodDay();
    const filterLicenseesByPeriod = (allLicensees) => {
      if (!dayFilter) return allLicensees; // If no period selected yet
      const filtered = {};
      for (const propertyCode in allLicensees) {
        const tenants = allLicensees[propertyCode].filter(tenant => tenant['RENT DATE'] === dayFilter);
        if (tenants.length > 0) {
          filtered[propertyCode] = tenants;
        }
      }
      return filtered;
    };

    if (searchTerm === '' && filter === 'all') {
      setFilteredLicensees(filterLicenseesByPeriod(licensees));
    } else {
      const baseFiltered = Object.keys(licensees).reduce((acc, propertyCode) => {
        const tenants = Array.isArray(licensees[propertyCode]) ? licensees[propertyCode].filter(
          tenant => {
            const matchesSearchTerm = tenant['REFERENCE CODE'].toLowerCase().includes(searchTerm.toLowerCase()) ||
                                      tenant.NAME.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                      tenant.SURNAME.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesFilter = (filter === 'all') ||
                                  (filter === 'paid' && hasPaidRent(tenant, paymentData)) ||
                                  (filter === 'unpaid' && !hasPaidRent(tenant, paymentData));
            return matchesSearchTerm && matchesFilter;
          }
        ) : [];
        if (tenants.length > 0) acc[propertyCode] = tenants;
        return acc;
      }, {});
      // Now filter these by the dayFilter
      setFilteredLicensees(filterLicenseesByPeriod(baseFiltered));
    }
  }, [searchTerm, filter, licensees, paymentData, selectedPeriod]);

  const handleHouseExpand = async (propertyCode) => {
    if (!licensees[propertyCode]) {
      await fetchTenantsForHouse(propertyCode);
    }
    setExpandedHouse(propertyCode === expandedHouse ? null : propertyCode);
  };

  const fetchTenantsForHouse = async (propertyCode) => {
    try {
      const licenseeCollection = collection(db, 'licensees');
      const licenseeSnapshot = await getDocs(licenseeCollection);

      const tenantsForHouse = licenseeSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(tenant => extractPropertyCode(tenant['REFERENCE CODE']) === propertyCode);

      setLicensees(prevState => ({ ...prevState, [propertyCode]: tenantsForHouse }));
    } catch (error) {
      console.error("Error fetching tenants for house:", error);
    }
  };

  const loadFullPaymentHistory = async (tenant) => {
    setHistoryLoading(true);

    const allPeriods = [];
    const years = Object.keys(availablePeriods).sort((a,b) => b - a);
    for (const y of years) {
      const months = Object.keys(availablePeriods[y]).sort((a,b) => {
        const monthOrder = { 
          "JANUARY": 1, "FEBRUARY": 2, "MARCH": 3, "APRIL": 4, "MAY": 5, "JUNE": 6, 
          "JULY": 7, "AUGUST": 8, "SEPTEMBER": 9, "OCTOBER": 10, "NOVEMBER": 11, "DECEMBER": 12 
        };
        return monthOrder[b] - monthOrder[a];
      });
      for (const m of months) {
        const periodsInMonth = availablePeriods[y][m];
        if (periodsInMonth.includes('15TH')) allPeriods.push({ year: y, month: m, period: '15TH' });
        if (periodsInMonth.includes('1ST')) allPeriods.push({ year: y, month: m, period: '1ST' });
      }
    }

    const maxLookback = 12; 
    const historicalData = [];
    for (let i = 0; i < Math.min(allPeriods.length, maxLookback); i++) {
      const { year, month, period } = allPeriods[i];
      const csvData = await fetchCSVForPeriod(year, month, period);
      const enrichedData = csvData.map(entry => {
        return { ...entry, Year: year, Month: month, Period: period };
      });
      historicalData.push(...enrichedData);
    }

    const tenantHistory = historicalData.filter(payment => payment['Ref Code'] === tenant['REFERENCE CODE']);
    setSelectedTenantHistory(tenantHistory);
    setSelectedTenantName(`${tenant.NAME} ${tenant.SURNAME}`);
    setHistoryLoading(false);
  };

  const fetchCSVForPeriod = async (year, month, period) => {
    const storage = getStorage();
    const folderPath = `Open Banking Tracking/${year}/${month}`;
    const folderRef = ref(storage, folderPath);

    try {
      const periodFiles = await listAll(folderRef);
      const matchingFile = periodFiles.items.find(item => item.name.includes(`_${period}_PAYMENT_LINKS.csv`));
      if (matchingFile) {
        const url = await getDownloadURL(matchingFile);
        const response = await fetch(url);
        const csvText = await response.text();
        return new Promise((resolve) => {
          Papa.parse(csvText, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
              resolve(result.data);
            },
            error: () => {
              resolve([]);
            }
          });
        });
      } else {
        return [];
      }
    } catch {
      return [];
    }
  };

  const openPaymentHistoryDialog = async (tenant) => {
    setHistoryDialogOpen(true);
    await loadFullPaymentHistory(tenant);
  };

  const closePaymentHistoryDialog = () => {
    setHistoryDialogOpen(false);
    setSelectedTenantHistory([]);
    setSelectedTenantName('');
  };

  const mainLoadingIndicator = (mainLoading || loadingPeriods) ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
      <CircularProgress />
    </Box>
  ) : null;

  const historyLoadingIndicator = historyLoading ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
      <CircularProgress />
    </Box>
  ) : null;

  if (isMobile) {
    return (
      <div>
        <h1>Admin Rent Management</h1>
        {mainLoadingIndicator}
        <Box mb={3}>
          <TextField
            label="Search Licensees or Properties"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>

        {Object.keys(filteredLicensees).length === 0 ? (
          <Typography>No houses found matching your search criteria.</Typography>
        ) : (
          availablePropertyCodes.map(propertyCode => (
            filteredLicensees[propertyCode] && (
              <Box key={propertyCode} mb={3}>
                <Typography variant="h6">{propertyCode}</Typography>
                {filteredLicensees[propertyCode].map(tenant => (
                  <Card key={tenant.id} sx={{ marginBottom: 2 }}>
                    <CardContent>
                      <Typography>Reference Code: {tenant['REFERENCE CODE']}</Typography>
                      <Typography>
                        Rent Status: {hasPaidRent(tenant, paymentData) 
                          ? `Paid on ${getPaymentDate(tenant, paymentData)}` 
                          : 'Unpaid'}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => openPaymentHistoryDialog(tenant)}
                      >
                        View History
                      </Button>
                      {!hasPaidRent(tenant, paymentData) && (
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onClick={() => sendReminderEmail(tenant, setSnackbarState)}
                        >
                          Send Reminder
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                ))}
              </Box>
            )
          ))
        )}

        <Dialog open={historyDialogOpen} onClose={closePaymentHistoryDialog} fullWidth maxWidth="md">
          <DialogTitle>Payment History for {selectedTenantName}</DialogTitle>
          <DialogContent>
            {historyLoadingIndicator}
            {!historyLoading && (selectedTenantHistory.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Year</TableCell>
                    <TableCell>Month</TableCell>
                    <TableCell>Period</TableCell>
                    <TableCell>Ref Code</TableCell>
                    <TableCell>Rent</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Date Paid</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedTenantHistory.map((entry, index) => (
                    <TableRow key={index}>
                      <TableCell>{entry['Year']}</TableCell>
                      <TableCell>{entry['Month']}</TableCell>
                      <TableCell>{entry['Period']}</TableCell>
                      <TableCell>{entry['Ref Code']}</TableCell>
                      <TableCell>{entry['Rent'] ? `€${entry['Rent']}` : 'N/A'}</TableCell>
                      <TableCell>{entry['succeeded'] === 'succeeded' ? 'Paid' : 'Unpaid'}</TableCell>
                      <TableCell>{entry['succeeded'] === 'succeeded' ? entry['date paid'] : ''}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              !historyLoading && <Typography>No payment history found.</Typography>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={closePaymentHistoryDialog}>Close</Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbarState.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          sx={{ 
            '& .MuiSnackbarContent-root': {
              fontSize: '1.5rem',
              padding: '16px 24px',
            }
          }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
            {snackbarState.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }

  return (
    <div>
      <h1>Admin Rent Management</h1>
      {mainLoadingIndicator}
      <Box mb={3}>
        <TextField
          label="Search Licensees or Properties"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>

      <Box mb={3}>
        <ButtonGroup variant="outlined">
          <Button
            variant={filter === 'all' ? 'contained' : 'outlined'}
            onClick={() => setFilter('all')}
          >
            Show All
          </Button>
          <Button
            variant={filter === 'paid' ? 'contained' : 'outlined'}
            onClick={() => setFilter('paid')}
          >
            Show Paid
          </Button>
          <Button
            variant={filter === 'unpaid' ? 'contained' : 'outlined'}
            onClick={() => setFilter('unpaid')}
          >
            Show Unpaid
          </Button>
        </ButtonGroup>
      </Box>

      <Box mb={3}>
        <FormControl fullWidth>
          <InputLabel id="year-select-label" sx={{ fontSize: '16px', paddingLeft: '5px' }}>Year</InputLabel>
          <Select
            labelId="year-select-label"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            sx={{ padding: '8px', marginTop: '8px' }}
          >
            {Object.keys(availablePeriods).map(year => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="month-select-label" sx={{ fontSize: '16px', paddingLeft: '5px' }}>Month</InputLabel>
          <Select
            labelId="month-select-label"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            disabled={!selectedYear}
            sx={{ padding: '8px', marginTop: '8px' }}
          >
            {selectedYear && Object.keys(availablePeriods[selectedYear]).map(month => (
              <MenuItem key={month} value={month}>
                {month.charAt(0) + month.slice(1).toLowerCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="period-select-label" sx={{ fontSize: '16px', paddingLeft: '5px' }}>Rent Period</InputLabel>
          <Select
            labelId="period-select-label"
            value={selectedPeriod}
            onChange={(e) => setSelectedPeriod(e.target.value)}
            disabled={!selectedMonth || loadingPeriods || !availablePeriods[selectedYear]?.[selectedMonth]?.length}
            sx={{ padding: '8px', marginTop: '8px' }}
          >
            {Array.isArray(availablePeriods[selectedYear]?.[selectedMonth]) &&
              availablePeriods[selectedYear][selectedMonth].map(period => (
                <MenuItem key={period} value={period}>
                  {period}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>

      <Box mt={3}>
        <Typography variant="h6">Total Rent Due: €{totalDue.toFixed(2)}</Typography>
        <Typography variant="h6">Total Rent Received: €{totalReceived.toFixed(2)}</Typography>
        <Typography variant="h6">Total Rent Still Due: €{(totalDue - totalReceived).toFixed(2)}</Typography>
      </Box>

      {Object.keys(filteredLicensees).length === 0 ? (
        <Typography>No houses found matching your search criteria.</Typography>
      ) : (
        availablePropertyCodes.map(propertyCode => (
          filteredLicensees[propertyCode] && (
            <Accordion key={propertyCode} expanded={expandedHouse === propertyCode} onChange={() => handleHouseExpand(propertyCode)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${propertyCode}-content`} id={`${propertyCode}-header`}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>{propertyCode}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {Array.isArray(filteredLicensees[propertyCode]) && filteredLicensees[propertyCode].length > 0 ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Reference Code</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Rent</TableCell>
                        <TableCell>Rent Status</TableCell>
                        <TableCell>Date Paid</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredLicensees[propertyCode].map((tenant) => (
                        <TableRow key={tenant.id}>
                          <TableCell>{tenant['REFERENCE CODE']}</TableCell>
                          <TableCell>{tenant.NAME} {tenant.SURNAME}</TableCell>
                          <TableCell>{tenant.EMAIL}</TableCell>
                          <TableCell>{tenant.RENT ? `€${tenant.RENT}` : 'N/A'}</TableCell>
                          <TableCell>{hasPaidRent(tenant, paymentData) ? 'Paid' : 'Unpaid'}</TableCell>
                          <TableCell>{hasPaidRent(tenant, paymentData) ? getPaymentDate(tenant, paymentData) : ''}</TableCell>
                          <TableCell>
                            <Button 
                              variant="outlined" 
                              color="primary" 
                              onClick={() => openPaymentHistoryDialog(tenant)}
                              sx={{ mr: 1 }}
                            >
                              View History
                            </Button>
                            {!hasPaidRent(tenant, paymentData) && (
                              <Button 
                                variant="outlined" 
                                color="secondary" 
                                onClick={() => sendReminderEmail(tenant, setSnackbarState)}
                              >
                                Send Reminder
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography>No tenants found for this house.</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          )
        ))
      )}

      <Dialog open={historyDialogOpen} onClose={closePaymentHistoryDialog} fullWidth maxWidth="md">
        <DialogTitle>Payment History for {selectedTenantName}</DialogTitle>
        <DialogContent>
          {historyLoadingIndicator}
          {!historyLoading && (selectedTenantHistory.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Month</TableCell>
                  <TableCell>Period</TableCell>
                  <TableCell>Ref Code</TableCell>
                  <TableCell>Rent</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date Paid</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedTenantHistory.map((entry, index) => (
                  <TableRow key={index}>
                    <TableCell>{entry['Year']}</TableCell>
                    <TableCell>{entry['Month']}</TableCell>
                    <TableCell>{entry['Period']}</TableCell>
                    <TableCell>{entry['Ref Code']}</TableCell>
                    <TableCell>{entry['Rent'] ? `€${entry['Rent']}` : 'N/A'}</TableCell>
                    <TableCell>{entry['succeeded'] === 'succeeded' ? 'Paid' : 'Unpaid'}</TableCell>
                    <TableCell>{entry['succeeded'] === 'succeeded' ? entry['date paid'] : ''}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            !historyLoading && <Typography>No payment history found.</Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={closePaymentHistoryDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarState.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        sx={{ 
          '& .MuiSnackbarContent-root': {
            fontSize: '1.5rem',
            padding: '16px 24px',
          }
        }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AdminRentPage;
